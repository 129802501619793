@import "./colors.scss";

.services {
    background-color: $color_1;
    overflow: hidden;
    padding: 20px 100px;
  
    .caroussel {
        display: flex;
        justify-content: center;
        align-items: center;

        // svg[class^="fa"] {
        //     font-size: 30px;
        //     color: red;
        // }

        // div[class^="ca"] {
        //     font-size: 30px;
        //     color: red;
        // }

        .fa-circle-chevron-left {
            position: absolute;
            left: 40px;
            cursor: pointer;
        }

        .fa-circle-chevron-right {
            position: absolute;
            right: 40px;
            cursor: pointer;
        }
    }
  }