@import "./colors.scss";

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color_1;
    color: $color_4;
    font-weight: bold;

    height: 30%;
    padding: 1.3em 8em;

    img {
        height: 5em;
    }
}