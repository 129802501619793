@import "./colors.scss";

footer {
    text-align: center;
    padding: 20px;
    background-color: $color_3;
    color: $color_2;

    a {
        color: $color_2;
        font-weight: bold;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}