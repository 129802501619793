@import "./colors.scss";

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  color: #333;
}

.presentation {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;

  img {
    width: 50%;
    height: auto;
    margin: 20px;
    border-radius: 25px;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 50px;

    & > * {
      text-align: center;
    }
  }
}

.about {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 50%;
    height: auto;
    margin: 20px;
    border-radius: 25px;
  }

  div {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 50px;
    margin-left: 50px;

    & > * {
      text-align: center;
    }
  }
}

.kapnumerik {
  background-color: $color_1;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;

  img {
    width: 50%;
    height: auto;
    margin: 20px;
    border-radius: 25px;
  }

  div {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin-right: 50px;

    & > * {
      text-align: center;
    }
  }
}